<template>
  <div>
    <SubHeader :data="data" />
    <div class="container">
      <div class="dashboard">
        <div style="margin-bottom: 30px;">
          <h1 style="font-size: 34px; font-weight: 500;">{{ $t(`HelpCenter['PopularsTopics']`) }}</h1>
            <el-row :gutter="24">
              <el-col v-for="(listDas, index) in dashbaordHelpCenter" :key="index" :xs="12" :sm="12" :md="12" :lg="8">
                  <div @click="goToList(listDas.title)" class="box-dashbaord">
                    <img :src="listDas.icon" />
                    <h1>{{ $t(`HelpCenter['${listDas.title}']`) }}</h1>
                  </div>
              </el-col>
            </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SubHeader from '../components/SubHeader.vue'

export default {
  name: 'HeplCenter',
  components: {
    SubHeader
  },
  data() {
    return {
      activeNames: '',
      data: {
        label_name: 'helpCenter',
        content_desc: 'helpCenterContent',
        img: require('@/assets/image/sub-header/help-center.png')
      },
      compName: '',
      dashbaordHelpCenter: [
        { id: 0, title: 'GetStart', icon: require('@/assets/image/help-center/getStart-icon.png') },
        { id: 1, title: 'MosAppInstandMessaging', icon: require('@/assets/image/help-center/instand-message-icon.png') },
        { id: 2, title: 'VoiceCall', icon: require('@/assets/image/help-center/voice-call-icon.png') },
        { id: 3, title: 'Channel', icon: require('@/assets/image/help-center/chanele-icon.png') },
        { id: 4, title: 'EncryptedGroup', icon: require('@/assets/image/help-center/encrypted-group-icon.png') },
        { id: 5, title: 'PrivacySecurity', icon: require('@/assets/image/help-center/privacy-icon.png') },
        { id: 6, title: 'VideoCall', icon: require('@/assets/image/help-center/video-call.png') },
        { id: 7, title: 'Accounts', icon: require('@/assets/image/help-center/accounts.png') },
        { id: 8, title: 'Security', icon: require('@/assets/image/help-center/security.png') }
      ]
    }
  },

  methods: {
    goToList(title) {
      this.$router.push({ name: 'Help center list', params: { title: title } })
    }
  }
}
</script>
<style lang="css">
.dashboard {
  margin-top: 10px;
  padding: 0 30px;
  width: 80%;
  margin: 25px auto;
}
@media screen and (max-width: 992px) {
  .dashboard {
    padding: 0 0px;
    width: 100%;
    margin: 25px auto;
  }
}
.dashboard h1 {
  font-weight: 500;
  margin-bottom: 15px;
  /* font-size: 34px; */
}
.box-dashbaord {
  background-color: #F1F5FF;
  padding: 10px 15px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 25px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media screen and (min-width: 992px) {
  .box-dashbaord {
    display: block;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.box-dashbaord:hover {
  background-color: #ffff;
  box-shadow: 3px 2px 15px -7px gray;
}
.box-dashbaord img {
    margin-bottom: 15px;
}
@media screen and (max-width: 677px) {
  .box-dashbaord {
    height: 145px;
  }
  .box-dashbaord img {
      width: 65px;
    }
}
</style>
<style lang="less" scoped>
.container-text {
  font-size: 20px;
  font-weight: 500;
  // padding: 40px 0 70px;
}
@media screen and (max-width: 992px) {
  .container-text {display: none;}
}
</style>
