<template lang="">
  <div class="banner">
    <div class="container">
      <el-row :gutter="24">
        <el-col :xs="24" :md="12">
          <div class="header-content">
            <h1>{{ $t(`Layout['${this.data.label_name}']`) }}</h1>
            <p>{{ $t(`Layout['${this.data.content_desc}']`) }}</p>
          </div>
        </el-col>
        <el-col :xs="24" :md="12">
          <div class="header-img">
            <img :src="this.data.img" />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: { type: Object, default: () => { return {} } }
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="less" scoped>
  .banner {
    position: relative;
    background-color: #E7EEFF99;
    background-position: center;
    height: auto;
    .container {
      color: #020202;
      position: relative;
      .header-content {
        margin-top: 100px;
        height: 245px;
        @media screen and (max-width: 992px) {
          height: auto;
        }
        h1 {
          font-size: 51px;
          font-weight: 700;
        }
        p {
          font-size: 17px;
        }
      }
    }
    .header-img {
      margin-top: 100px;
      justify-content: center;
      display: flex;
      @media screen and (max-width: 992px) {
        margin-top: 0px;
      }
      img {
        width: 70%;
        @media screen and (max-width: 992px) {
          // width: 50%;
          margin-bottom: 20px;
        }
      }
    }
  }
</style>
